import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Vendor } from "../../../utils/httpUtils";
import LoadingIcon from "../../commonComponents/LoadingIcon";
import { useNavigate } from "react-router-dom";
import {
  formatDateTime,
  formatAddressNoZip,
  determineName,
} from "../../../utils/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Card,
  Grid,
  CardContent,
} from "@mui/material";
import { TableHeader } from "../../reusableComponents/Typography";

const RequestedJobsMobile = () => {
  const [requestedJobs, setRequestedJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  useEffect(() => {
    getRequestedJobs();
  }, []);

  useEffect(() => {
    if (requestedJobs?.length > 0) {
      setIsLoading(false);
    }
  }, [requestedJobs]);

  const getRequestedJobs = () => {
    Vendor()
      .getJson("requestedJobs")
      .then((response) => {
        if (response.data) {
          setRequestedJobs(response.data);
          setIsLoading(false);
        } else {
          return;
        }
      });
  };

  const goToJob = (jobId) => {
    navigate(`/job/${jobId}`);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
          <CardContent>
            <Typography
              variant="h4"
              component="div"
              sx={{ marginBottom: 1, userSelect: "none" }}
            >
              Requested Jobs
            </Typography>

            <TableContainer
              component={Paper}
              style={{
                marginBottom: "16px",
                userSelect: "none",
                maxWidth: "100%",
                overflowX: "auto",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableHeader
                        variant="subtitle1"
                        component="div"
                        sx={{ fontSize: "26px" }}
                      >
                        Address
                      </TableHeader>
                    </TableCell>
                    <TableCell>
                      <TableHeader
                        variant="subtitle1"
                        component="div"
                        sx={{ fontSize: "26px" }}
                      >
                        Customer
                      </TableHeader>
                    </TableCell>
                    {/* <TableCell>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ fontSize: "26px" }}
                      >
                        Requested Date/Time
                      </Typography>
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <LoadingIcon />
                      </TableCell>
                    </TableRow>
                  ) : requestedJobs && requestedJobs.length > 0 ? (
                    requestedJobs.map((job) => (
                      <ClickableTableRow
                        key={job._id}
                        onClick={() => goToJob(job._id)}
                      >
                        <TableCell>
                          <Typography sx={{ fontSize: "18px" }}>
                            {formatAddressNoZip(job.address.readableAddress)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ fontSize: "18px" }}>
                            {determineName(job.customer)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ fontSize: "18px" }}>
                            {formatDateTime(job.dateRequested)}
                          </Typography>
                        </TableCell>
                      </ClickableTableRow>
                    ))
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default RequestedJobsMobile;
