import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Job, Quote, Customer, Email } from "../../utils/httpUtils";
import CommonNavbar from "../../components/commonComponents/CommonNavbar";
import useWindowDimensions from "../../hooks/useWindowDemsions";
import QuotesSmall from "./QuotesSmall";
import QuotesLarge from "./QuotesLarge";
import CustomerInfoModal from "../../modals/CustomerInfoModal";

export default function Quotes() {
  const { height, width } = useWindowDimensions();
  const [size, setSize] = useState("");
  const theme = useTheme();
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [property, setProperty] = useState();
  const [userId, setUserId] = useState();
  const { requestId, emailId } = useParams();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [job, setJob] = useState();
  const [vendor, setVendor] = useState({});
  const [displayCustomerInfoModal, setDisplayCustomerInfoModal] =
    useState(false);
  const [currentQuotesLoading, setCurrentQuotesLoading] = useState(true);
  const [customerInfo, setCustomerInfo] = useState({});
  const [coupons, setCoupons] = useState([]);
  const [allQuotes, setAllQuotes] = useState([]);
  const [appliedCoupons, setAppliedCoupons] = useState([]);
  const [includeLeadSource, setIncludeLeadSource] = useState(false);
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [dollarDiscount, setDollarDiscount] = useState("");


  useEffect(() => {
    if (coupons && coupons.length > 0) {
      const newAppliedCoupons = coupons.filter((coupon) =>
        determineIfCouponMet(coupon)
      );

      setAppliedCoupons(newAppliedCoupons);
    } else {
      setAppliedCoupons([]);
    }
  }, [selectedQuotes, coupons]);

  useEffect(() => {
    if (emailId) {
      const data = {
        emailId,
      };
      Email().getJson("updateEmailOpened", data);
    }
  }, [emailId]);

  useEffect(() => {
    let mostExpensiveQuote = null;
  
    allQuotes.forEach((quote) => {
      if (selectedQuotes.includes(quote._id)) {
        if (!mostExpensiveQuote || quote.price > mostExpensiveQuote.price) {
          mostExpensiveQuote = quote;
        }
      }
    });
  
    const updatedQuotes = allQuotes.map((quote) => {
      let couponPrice = quote.price;
  
      if (appliedCoupons.length > 0) {
        const appliedCoupon = appliedCoupons[0];
  
        if (appliedCoupon.discountUnit === "%") {
          couponPrice *= (100 - appliedCoupon.discountAmount) / 100;
        } else if (appliedCoupon.discountUnit === "$") {

          if (mostExpensiveQuote && quote._id === mostExpensiveQuote._id) {
            couponPrice -= appliedCoupon.discountAmount;
          }
        }
      }
  
      return {
        ...quote,
        couponPrice,
      };
    });
  
    setAllQuotes(updatedQuotes);
  }, [appliedCoupons, selectedQuotes]);

  const determineIfCouponMet = (coupon) => {

    const selectedQuotesSum = allQuotes.reduce((sum, quote) => {
      if (selectedQuotes.includes(quote._id)) {
        return sum + quote.price;
      }
      return sum;
    }, 0);
  
    if (coupon.minPurchase && parseInt(coupon.minPurchase) > selectedQuotesSum) {
      return false;
    }
    if (coupon.minServices <= selectedQuotes.length) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const breakpoints = theme.breakpoints.keys;
    const currentBreakpoint = breakpoints.reduce((acc, key) => {
      return width >= theme.breakpoints.values[key] ? key : acc;
    }, "xs");

    setSize(currentBreakpoint);
  }, [width, theme.breakpoints]);

  useEffect(() => {
    getRequestById();
  }, []);

  useEffect(() => {
    customerInfoCheck();
  }, [requestId]);

  const customerInfoCheck = () => {
    const data = {
      requestId,
    };
    Customer()
      .getJson("customerInfoCheck", data)
      .then((response) => {
        setCustomerInfo(response.data);
      });
  };

  const getRequestById = () => {
    const data = {
      requestId,
    };

    Quote()
      .getJson("fetchRequest", data)
      .then((response) => {
        setAllQuotes(response.data.allQuotes);
        setVendor(response.data.vendor);
        setProperty(response.data.property);
        setUserId(response.data.userId);
        setCoupons(response.data.coupons);
        if (response.data.vendor.includeLeadSource) {
          setIncludeLeadSource(true);
          setLeadSourceOptions(
            response.data.vendor.leadSourceOptions
          );
        }
      });
  };

  const handleSelectQuote = (quoteId) => {
    setSelectedQuotes((prevSelectedQuotes) => {
      if (prevSelectedQuotes.includes(quoteId)) {
        return prevSelectedQuotes.filter((id) => id !== quoteId);
      } else {
        return [...prevSelectedQuotes, quoteId];
      }
    });
  };

  const closeCustomerInfoModal = () => {
    setDisplayCustomerInfoModal(false);
  };

  const openCustomerInfoModal = () => {
    setDisplayCustomerInfoModal(true);
  };

  const requestServices = () => {
    openCustomerInfoModal();
  };

  const createJob = (
    customerFirst,
    customerLast,
    customerPhone,
    customerNotes,
    leadSource
  ) => {
    const data = {
      userId,
      quoteIds: selectedQuotes,
      requestId,
      customerFirst,
      customerLast,
      customerPhone,
      customerNotes,
      appliedCoupons,
      leadSource,
    };
    Job()
      .postJson("createJobNoAuth", data)
      .then((response) => {
        if (response.error) {
          setError(response.response.data.message);
          return;
        }
        closeCustomerInfoModal();
        setJob(response.data.job._id);
        setError();
        setSuccess(true);
      });
  };

  return (
    <div>
      <CommonNavbar />
      <CustomerInfoModal
        createJob={createJob}
        open={displayCustomerInfoModal}
        onClose={closeCustomerInfoModal}
        customerInfo={customerInfo}
        includeLeadSource={includeLeadSource}
        leadSourceOptions={leadSourceOptions}
      />
      {size === "xs" && (
        <QuotesSmall
          selectedQuotes={selectedQuotes}
          onSelectQuote={handleSelectQuote}
          requestServices={requestServices}
          error={error}
          success={success}
          job={job}
          vendor={vendor}
          property={property}
          currentQuotesLoading={currentQuotesLoading}
          coupons={coupons}
          allQuotes={allQuotes}
          appliedCoupons={appliedCoupons}
        />
      )}
      {size === "sm" && (
        <QuotesSmall
          selectedQuotes={selectedQuotes}
          onSelectQuote={handleSelectQuote}
          requestServices={requestServices}
          error={error}
          success={success}
          job={job}
          vendor={vendor}
          property={property}
          currentQuotesLoading={currentQuotesLoading}
          coupons={coupons}
          allQuotes={allQuotes}
          appliedCoupons={appliedCoupons}
        />
      )}
      {size === "md" && (
        <QuotesSmall
          selectedQuotes={selectedQuotes}
          onSelectQuote={handleSelectQuote}
          requestServices={requestServices}
          error={error}
          success={success}
          job={job}
          vendor={vendor}
          property={property}
          currentQuotesLoading={currentQuotesLoading}
          coupons={coupons}
          allQuotes={allQuotes}
          appliedCoupons={appliedCoupons}
        />
      )}
      {size === "lg" && (
        <QuotesLarge
          selectedQuotes={selectedQuotes}
          onSelectQuote={handleSelectQuote}
          requestServices={requestServices}
          error={error}
          success={success}
          job={job}
          vendor={vendor}
          property={property}
          currentQuotesLoading={currentQuotesLoading}
          coupons={coupons}
          allQuotes={allQuotes}
          appliedCoupons={appliedCoupons}
        />
      )}
      {size === "xl" && (
        <QuotesLarge
          selectedQuotes={selectedQuotes}
          onSelectQuote={handleSelectQuote}
          requestServices={requestServices}
          error={error}
          success={success}
          job={job}
          vendor={vendor}
          property={property}
          currentQuotesLoading={currentQuotesLoading}
          coupons={coupons}
          allQuotes={allQuotes}
          appliedCoupons={appliedCoupons}
        />
      )}
    </div>
  );
}
