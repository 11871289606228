import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Autocomplete from "../commonComponents/AutoComplete";
import { useNavigate } from "react-router-dom";

const NewQuote = () => {
  const [selectedPropertyId, setSelectedPropertyId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedPropertyId) {
      goToAddress();
    }
  }, [selectedPropertyId]);

  const goToAddress = () => {
    navigate(`/address/${selectedPropertyId}`);
  };

  return (
    <Card
      style={{ width: "100%", maxHeight: "100vh", border: "1px solid #ccc", borderRadius: "10px" }}
    >
      <CardContent>
        <Typography
          variant="h4"
          component="div"
          sx={{ marginBottom: 1, userSelect: "none" }}
        >
          Add Quotes to New Address
        </Typography>
        <Autocomplete setSelectedPropertyId={setSelectedPropertyId} />
      </CardContent>
    </Card>
  );
};

export default NewQuote;

// import TextField from "@mui/material/TextField";
// import { Vendor, Utils } from "../../utils/httpUtils";
// import LoadingIcon from "../commonComponents/LoadingIcon";
// import AddressCard from "./AddressCard";

//Deprecated Google autocomplete stuff; not used anymore but kept for posterity.
// const [addresses, setAddresses] = useState([]);
// const [filteredAddresses, setFilteredAddresses] = useState([]);
// const [isLoading, setIsLoading] = useState(true);
// const [searchText, setSearchText] = useState("");

//   const [places, setPlaces] = useState("")
//   console.log("places: ", places)

//   useEffect(() => {
//     if (!places) {
//       Utils().getJson('fetchGoogle')
//         .then(response => setPlaces(response.data.places))
//     }
//   }, []);

//   useEffect(() => {
//     getAddresses();
//   }, []);

//   useEffect(() => {
//     if (addresses?.length > 0) {
//       setIsLoading(false);
//       setFilteredAddresses(addresses);
//     }
//   }, [addresses]);

//   const getAddresses = () => {
//     Vendor()
//       .getJson("getAddresses")
//       .then((response) => {
//         setAddresses(response.data);
//       });
//   };
