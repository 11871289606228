import React, { useState, useEffect } from "react";
import { Quote, Service, Job } from "../utils/httpUtils";
import RecurringServiceModal from "./RecurringServiceModal";
import CancelRecurringModal from "./CancelRecurringModal";
import ClearIcon from "@mui/icons-material/Clear";
import ScheduleJobModal from "./ScheduleJobModal";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Dialog,
  DialogContent,
  Typography,
  Card,
  CardContent,
  MenuItem,
  Select,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import CustomCheckbox from "../components/reusableComponents/Customcheckbox";
import { GreenButton, RedButton } from "../components/reusableComponents/Buttons";

const CreateJobModal = ({
  open,
  onClose,
  createRecurring,
  properties,
  customer,
}) => {
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [customerNotes, setCustomerNotes] = useState("");
  const [isOpen, setIsOpen] = useState(open);
  const [quotes, setQuotes] = useState([]);
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [showRecurringServiceModal, setShowRecurringServiceModal] =
    useState(false);
  const [showCancelRecurring, setShowCancelRecurring] = useState(false);
  const [showScheduleJobModal, setShowScheduleJobModal] = useState(false);
  const [scheduledDate, setScheduledDate] = useState("");

  useEffect(() => {
    setIsOpen(open);
    setSelectedPropertyId(properties[0]);
    setSelectedQuotes([]);
    setServices([]);
    getQuotesAtProperty();
    setScheduledDate("");
  }, [open]);

  useEffect(() => {
    getServices();
  }, [selectedQuotes]);

  useEffect(() => {
    getQuotesAtProperty();
  }, [selectedPropertyId]);

  const getQuotesAtProperty = () => {
    if (!selectedPropertyId) {
      return;
    }
    const data = {
      propertyId: selectedPropertyId,
      customerId: customer._id,
    };
    Quote()
      .getJson("getAllQuotesByVendorAtAddress", data)
      .then((response) => {
        setQuotes(response.data);
      });
  };

  const scheduleJob = () => {
    const quoteIds = selectedQuotes.map((quote) => quote._id);
    const data = {
      quoteIds,
      customer,
      propertyId: selectedPropertyId,
      selectedDate: scheduledDate,
    };
    Job()
      .postJson("createJob", data)
      .then((response) => {
        handleClose()
      });
  };

  const getServices = () => {
    if (selectedQuotes.length > 0) {
      const quoteIds = selectedQuotes.map((quote) => quote._id);
      const data = {
        quoteIds,
        customerId: customer?._id,
      };
      Service()
        .getJson("getServices", data)
        .then((response) => {
          setServices(response.data.services);
        });
    }
  };

  const handleAddQuote = (event) => {
    const selectedQuote = quotes.find(
      (quote) => quote._id === event.target.value
    );
    setSelectedQuotes([...selectedQuotes, selectedQuote]);
  };

  const handleRemoveQuote = (service) => {
    const updatedQuotes = selectedQuotes.filter(
      (quote) => quote._id !== service.quoteId
    );

    setSelectedQuotes([...updatedQuotes]);

    if (updatedQuotes.length === 0) {
      setSelectedQuotes([]);
      setServices([]);
    }
  };

  const handleCheckboxChange = (service) => {
    setSelectedService(service);
    if (service.recurring?.isRecurring) {
      setShowCancelRecurring(true);
    } else {
      setShowRecurringServiceModal(true);
    }
  };

  const formatAddress = (fullAddress) => {
    if (!fullAddress) {
      return "";
    }
    const parts = fullAddress.split(",");
    const formattedParts = parts
      .slice(0, parts.length - 2)
      .concat(parts[parts.length - 2].split(" ").slice(0, -1))
      .join(",")
      .replace(/,+/g, ",")
      .replace(/,(\S)/g, ", $1");

    return formattedParts.trim();
  };

  const formatDate = (date) => {
    const localDateOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
    };

    const localDate = new Date(date).toLocaleString(
      undefined,
      localDateOptions
    );

    return localDate;
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handlePropertyChange = (event) => {
    setSelectedQuotes([]);
    setServices([]);

    setSelectedPropertyId(event.target.value);
  };

  const handleCloseRecurringModal = () => {
    setShowRecurringServiceModal(false);
  };

  const handleCloseCancelRecurringModal = () => {
    setShowCancelRecurring(false);
  };

  const handleCloseScheduleModal = () => {
    setShowScheduleJobModal(false);
  };

  const handleUpdateDate = (date) => {
    setScheduledDate(date);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={true} maxWidth="lg">
      <RecurringServiceModal
        open={showRecurringServiceModal}
        onClose={handleCloseRecurringModal}
        service={selectedService}
        handleUpdate={getServices}
        propertyId={selectedPropertyId}
        customerId={customer?._id}
      />
      <CancelRecurringModal
        open={showCancelRecurring}
        onClose={handleCloseCancelRecurringModal}
        service={selectedService}
        handleUpdate={getServices}
      />
      <ScheduleJobModal
        open={showScheduleJobModal}
        onClose={handleCloseScheduleModal}
        jobId={null}
        handleUpdateDate={(date) => handleUpdateDate(date)}
      />
      <DialogContent>
        {/* <Card
          style={{ width: "100%", border: "1px solid #ccc", marginTop: "30px" }}
        > */}
          <CardContent>
            <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
              Create Job
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Grid item xs={3}>
                    <Typography variant="h5" style={{ marginRight: "16px" }}>
                      Select Property:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      value={selectedPropertyId || ""}
                      onChange={handlePropertyChange}
                      fullWidth
                    >
                      {properties.map((property) => (
                        <MenuItem key={property._id} value={property._id}>
                          {property.address.readableAddress}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Grid item xs={3}>
                    <Typography variant="h5" style={{ marginRight: "16px" }}>
                      Select Services:
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      value={
                        selectedQuotes.length > 0
                          ? selectedQuotes[selectedQuotes.length - 1]._id
                          : ""
                      }
                      onChange={handleAddQuote}
                      fullWidth
                      sx={{ maxWidth: "300px" }}
                    >
                      {quotes?.map((quote) => (
                        <MenuItem key={quote._id} value={quote._id}>
                          {quote.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Grid item xs={3}>
                    <Typography variant="h5" style={{ marginRight: "16px" }}>
                      Scheduled Date:
                    </Typography>
                  </Grid>
                  <Typography variant="h5" style={{ marginRight: "16px" }}>
                    {scheduledDate ? (
                      <>
                        <Grid item xs={12}>
                          {formatDate(scheduledDate)}
                          <GreenButton
                            variant="contained"
                            color="primary"
                            onClick={() => setShowScheduleJobModal(true)}
                            sx={{ marginLeft: "20px" }}
                          >
                            Reschedule Job
                          </GreenButton>
                        </Grid>
                      </>
                    ) : (
                      <GreenButton
                        variant="contained"
                        color="primary"
                        onClick={() => setShowScheduleJobModal(true)}
                      >
                        Schedule Job
                      </GreenButton>
                    )}
                  </Typography>
                </div>
              </Grid>
            </Grid>

            {services && services.length > 0 && (
              <CardContent>
                <TableContainer component={Card}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Service</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Recurring</TableCell>
                        <TableCell>Frequency</TableCell>
                        <TableCell>Last Performed</TableCell>
                        <TableCell>Suggested Next Date</TableCell>
                        <TableCell>Remove</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {services &&
                        services?.map((service, index) => (
                          <TableRow key={index}>
                            <TableCell>{service?.quoteTitle}</TableCell>
                            <TableCell>
                              {service?.readableAddress
                                ? formatAddress(service.readableAddress)
                                : ""}
                            </TableCell>
                            <TableCell>
                              ${service?.quotePrice?.toFixed(2)}
                            </TableCell>
                            <TableCell>
                              <CustomCheckbox 
                                checked={!!service?.recurring?.isRecurring}
                                onChange={() => handleCheckboxChange(service)}
                              />
                            </TableCell>
                            <TableCell>
                              {service?.recurring?.recurringFrequency
                                ? `${service?.recurring.recurringFrequency} ${service?.recurring.recurringUnit}`
                                : "-"}
                            </TableCell>
                            <TableCell>
                              {service.recurring?.lastPerformed
                                ? formatDate(service.recurring.lastPerformed)
                                : "-"}
                            </TableCell>
                            <TableCell>
                              {service.recurring?.recommendedNext
                                ? formatDate(service.recurring.recommendedNext)
                                : "-"}
                            </TableCell>
                            <TableCell>
                              {/* //kevin iconbutton */}
                              <IconButton
                                onClick={() => handleRemoveQuote(service)}
                                style={{ display: "inline-block" }}
                              >
                                <DeleteIcon />
                              </IconButton>

                              {/* <IconButton
                        aria-label="delete"
                        onClick={() => deleteCoupon(coupon._id)}
                      >
                        <DeleteIcon />
                      </IconButton> */}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            )}

            <GreenButton
              variant="contained"
              color="primary"
              onClick={scheduleJob}
              sx={{ marginRight: "10px", marginTop: "20px" }}
              disabled={!scheduledDate || !selectedQuotes || selectedQuotes?.length === 0 }
            >
              Create Job
            </GreenButton>
            <RedButton
              variant="contained"
              color="primary"
              onClick={onClose}
              sx={{ marginTop: "20px" }}
            >
              Cancel
            </RedButton>
          </CardContent>
        {/* </Card> */}
      </DialogContent>
    </Dialog>
  );
};

export default CreateJobModal;
