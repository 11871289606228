import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Job } from "../../utils/httpUtils";
import CommonNavbar from "../../components/commonComponents/CommonNavbar";
import useWindowDimensions from "../../hooks/useWindowDemsions";
import JobSmall from "./JobSmall";
import JobMedium from "./JobMedium";
import JobLarge from "./JobLarge";

export default function TrackJob() {
  const { height, width } = useWindowDimensions();
  const [size, setSize] = useState("");
  const theme = useTheme();
  const { id } = useParams();
  const [vendor, setVendor] = useState({});
  const [job, setJob] = useState();
  const [coupon, setCoupon] = useState();
  

  useEffect(() => {
    const breakpoints = theme.breakpoints.keys;
    const currentBreakpoint = breakpoints.reduce((acc, key) => {
      return width >= theme.breakpoints.values[key] ? key : acc;
    }, "xs");

    setSize(currentBreakpoint);
  }, [width, theme.breakpoints]);

  useEffect(() => {
    getJob();
  }, []);

  const getJob = () => {
    const data = {
      jobId: id,
    };
    Job()
      .getJson("getJob", data)
      .then((response) => {
        setData(response.data);
      });
  };

  const formatDate = (date) => {
    const localDateOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    const localDate = new Date(date).toLocaleString(
      undefined,
      localDateOptions
    );

    return localDate;
  };

  const setData = (data) => {
    const vendor = {
      companyName: data.vendor.companyName,
      email: data.vendor.email,
      phoneNumber: data.vendor.phoneNumber,
      logo: data.vendor.logo,
      reviewLink: data.vendor.reviewLink
    };
    const job = {
      address: data.job.address,
      services: data.services,
      jobStatus: data.job.jobStatus,
      totalPrice: data.job.totalPrice,
      paymentStatus: data.job.paymentStatus,
      scheduledDate: formatDate(data.job.scheduledDate),
      coupon: data.coupon,
    };
    setVendor(vendor);
    setJob(job);
  };

  return (
    <div>
      <CommonNavbar />
      <JobLarge job={job} vendor={vendor} />
      {/* {size === 'xs' && <JobLarge job={job} vendor={vendor} />}
      {size === 'sm' && <JobLarge job={job} vendor={vendor} />}
      {size === 'md' && <JobLarge job={job} vendor={vendor} />}
      {size === 'lg' && <JobLarge job={job} vendor={vendor} />}
      {size === 'xl' && <JobLarge job={job} vendor={vendor} />} */}
    </div>
  );
}
