import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Vendor } from "../../utils/httpUtils";
import LoadingIcon from "../commonComponents/LoadingIcon";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  styled,
} from "@mui/material";
import { CustomTextField } from "../reusableComponents/Inputs";

const ExistingQuotesByAddress = () => {
  const [addresses, setAddresses] = useState();
  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  useEffect(() => {
    getAddresses();
  }, []);

  useEffect(() => {
    if (addresses) {
      setFilteredAddresses(addresses);
    }
  }, [addresses]);

  const getAddresses = () => {
    Vendor()
      .getJson("getAddresses")
      .then((response) => {
        if (response?.response?.status === 200) {
          setAddresses(response.data);
        } else {
          setError("Error connecting to server");
        }
        setIsLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const searchText = e.target.value.toLowerCase();
    if (!addresses || addresses?.length === 0) {
      return;
    }

    const filtered = addresses.filter((address) =>
      (address.address.number + " " + address.address.street)
        .toLowerCase()
        .includes(searchText)
    );
    setSearchText(searchText);
    setFilteredAddresses(filtered);
  };

  const goToAddress = (addressId) => {
    navigate(`/address/${addressId}`);
  };

  return (
    <div style={{ maxHeight: "812px", overflow: "auto" }}>
      <Typography
        variant="h4"
        component="div"
        sx={{ marginBottom: "10px", userSelect: "none" }}
      >
        Existing Quotes by Address
      </Typography>
      {/* <Typography
        variant="h7"
        component="div"
        sx={{ marginBottom: 3, userSelect: "none" }}
      >
        Click to View Address
      </Typography> */}

      <CustomTextField
        label="Search By Address"
        variant="outlined"
        size="small"
        value={searchText}
        onChange={handleSearchChange}
        style={{ width: "300px", marginBottom: "16px" }}
      />

      <TableContainer component={Paper} style={{ marginBottom: "16px", marginTop: "10px" }}>
        <Table>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell>
                  <LoadingIcon />
                </TableCell>
              </TableRow>
            ) : filteredAddresses?.length > 0 ? (
              filteredAddresses.map((address) => {
                return (
                  <ClickableTableRow
                    key={address._id}
                    onClick={() => goToAddress(address._id)}
                  >
                    <TableCell>
                      <Typography sx={{ fontSize: "20px" }}>
                        {address.address.number} {address.address.street}
                      </Typography>
                    </TableCell>
                  </ClickableTableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell>
                  <Typography sx={{ userSelect: "none" }}>
                    No addresses found.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ExistingQuotesByAddress;
