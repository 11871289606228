import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Vendor } from "../../../utils/httpUtils";
import LoadingIcon from "../../commonComponents/LoadingIcon";
import { useNavigate } from "react-router-dom";
import {
  formatDateTime,
  formatAddressNoZip,
  determineName,
} from "../../../utils/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Card,
  Grid,
  CardContent,
} from "@mui/material";
import { TableHeader } from "../../reusableComponents/Typography";

const RequestedJobsDesktop = () => {
  const [requestedJobs, setRequestedJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  useEffect(() => {
    getRequestedJobs();
  }, []);

  useEffect(() => {
    if (requestedJobs?.length > 0) {
      setIsLoading(false);
    }
  }, [requestedJobs]);

  const getRequestedJobs = () => {
    Vendor()
      .getJson("requestedJobs")
      .then((response) => {
        if (response.data) {
          setRequestedJobs(response.data);
          setIsLoading(false);
        } else {
          return;
        }
      });
  };

  const goToJob = (jobId) => {
    navigate(`/job/${jobId}`);
  };

  return (
    <>
      <Card
        style={{
          width: "100%",
          border: "1px solid #ccc",
          borderRadius: "10px",
        }}
      >
        <CardContent>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="div"
                sx={{ marginBottom: 1, userSelect: "none" }}
              >
                Requested Jobs
              </Typography>

              <TableContainer
                component={Paper}
                style={{ marginBottom: "16px", userSelect: "none" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableHeader>Address</TableHeader>
                      </TableCell>
                      <TableCell>
                        <TableHeader>Customer/Account</TableHeader>
                      </TableCell>
                      <TableCell>
                        <TableHeader>Requested Date/Time</TableHeader>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <LoadingIcon />
                        </TableCell>
                      </TableRow>
                    ) : requestedJobs && requestedJobs.length > 0 ? (
                      requestedJobs.map((job) => (
                        <ClickableTableRow
                          key={job._id}
                          onClick={() => goToJob(job._id)}
                        >
                          <TableCell>
                            <Typography sx={{ fontSize: "18px" }}>
                              {formatAddressNoZip(job.address.readableAddress)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ fontSize: "18px" }}>
                              {job.customer && determineName(job.customer)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ fontSize: "18px" }}>
                              {formatDateTime(job.dateRequested)}
                            </Typography>
                          </TableCell>
                        </ClickableTableRow>
                      ))
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default RequestedJobsDesktop;
