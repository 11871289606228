import React, { useEffect, useState } from "react";
import { Customer } from "../utils/httpUtils";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
  CardContent,
} from "@mui/material";
import {
  GreenButton,
  RedButton,
} from "../components/reusableComponents/Buttons";
import {
  CustomSelectWithLabelNoAdd,
  CustomTextField,
} from "../components/reusableComponents/Inputs";

const ManualPaymentModal = ({ open, onClose, job, invoice, handleUpdate }) => {
  const [paymentMethod, setPaymentMethod] = useState("Credit Card");
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [checkNumber, setCheckNumber] = useState("");

  const paymentOptions = ["Credit Card", "Check", "Cash"];

  useEffect(() => {
    if (job?.totalPrice) {
      setPaymentAmount(job?.totalPrice?.toFixed(2));
    }
    if (invoice?.totalPrice) {
      setPaymentAmount(invoice?.totalPrice?.toFixed(2));
    }
  }, [job, invoice]);

  const handleUpdatePaymentAmount = (input) => {
    const numericInput = input.replace(/[^\d.]/g, "");
    const parts = numericInput.split(".");

    let formattedInput = "";

    if (parts.length > 0) {
      formattedInput = parts[0];

      if (parts.length > 1) {
        formattedInput += `.${parts[1].slice(0, 2)}`;
      }
    }
    setPaymentAmount(formattedInput);
  };

  const submitPayment = () => {
    let data;
    const formattedPayment = paymentAmount.replace;
    if (job) {
      data = {
        customerId: job.customerId,
        paymentMethod,
        paymentAmount,
        checkNumber,
        invoiceId: job.invoiceId,
      };
    }
    if (invoice) {
      data = {
        customerId: invoice.customerId,
        paymentMethod,
        paymentAmount,
        checkNumber,
        invoiceId: invoice._id,
      };
    }

    Customer()
      .postJson("postPayment", data)
      .then((response) => {
        handleUpdate();
        onClose();
      });
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ textAlign: "center" }}>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CardContent>
          <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
            Add Payment
          </Typography>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12} sx={{ marginBottom: "20px" }}>
              <CustomSelectWithLabelNoAdd
                label="Payment Method"
                value={paymentMethod}
                sx={{ width: "40%" }}
                onChange={(e) => setPaymentMethod(e.target.value)}
                options={paymentOptions.map((option) => ({
                  label: option,
                  value: option,
                }))}
              />
            </Grid>
            {paymentMethod && paymentMethod === "Check" && (
              <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                <CustomTextField
                  label="Check Number"
                  value={checkNumber}
                  onChange={(e) => setCheckNumber(e.target.value)}
                  sx={{ width: "30%" }}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <CustomTextField
                label="Payment Amount"
                value={paymentAmount}
                onChange={(e) => {
                  handleUpdatePaymentAmount(e.target.value);
                }}
                sx={{ width: "30%" }}
              />
            </Grid>
            <DialogActions sx={{ justifyContent: "center", marginTop: "10px" }}>
              <GreenButton
                variant="contained"
                color="primary"
                onClick={submitPayment}
                style={{ margin: "5px" }}
              >
                Submit Payment
              </GreenButton>
              <RedButton
                variant="contained"
                color="primary"
                onClick={onClose}
                style={{ margin: "5px" }}
              >
                Cancel
              </RedButton>
            </DialogActions>
          </Grid>
        </CardContent>
      </DialogContent>
    </Dialog>
  );
};

export default ManualPaymentModal;
