import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import LoadingIcon from "../../components/commonComponents/LoadingIcon";
import VendorNavbar from "../../components/vendorComponents/VendorNavbar";
import { Vendor, Job, Admin } from "../../utils/httpUtils";
import { useNavigate } from "react-router-dom";
import { theme } from "../../App";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import {
  formatDateTime,
  formatAddress,
  formatPhoneNumber,
  determineName,
  formatStatus,
  formatAddressNoZip,
} from "../../utils/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from "@mui/material";
import { CustomTextField } from "../../components/reusableComponents/Inputs";
import { TableHeader } from "../../components/reusableComponents/Typography";

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("companyName");
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState("scheduledDate");
  const [vendors, setVendors] = useState([]);
  const navigate = useNavigate();

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  useEffect(() => {
    getAllVendors()
  }, []);

  const getAllVendors = () => {
    Admin().getJson('getAllVendors')
    .then(response => {
        console.log("response: ", response)
        setVendors(response.data.vendors)
        setIsLoading(false)
    })
  }

  const handleSort = (column) => {
    setSortOrder((prevOrder) =>
      column === sortColumn ? (prevOrder === "asc" ? "desc" : "asc") : "asc"
    );
    setSortColumn(column);
  };

  const sortedJobs = () => {
    if (!Array.isArray(jobs)) {
      return [];
    }

    return [...jobs].sort((a, b) => {
      let aValue = a[sortColumn];
      let bValue = b[sortColumn];

      if (sortColumn === "scheduledDate") {
        aValue = a.scheduledDate ? formatDateTime(a.scheduledDate) : "";
        bValue = b.scheduledDate ? formatDateTime(b.scheduledDate) : "";
      } else if (sortColumn === "jobStatus") {
      } else if (sortColumn === "paymentStatus") {
      } else if (sortColumn === "address") {
        aValue = a.address ? formatAddress(a.address.readableAddress) : "";
        bValue = b.address ? formatAddress(b.address.readableAddress) : "";
      } else if (sortColumn === "customerName") {
        aValue = `${a.customerFirst} ${a.customerLast}`;
        bValue = `${b.customerFirst} ${b.customerLast}`;
      } else if (sortColumn === "customerPhone") {
        aValue = a.customerPhone || "";
        bValue = b.customerPhone || "";
      }

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      }
    });
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredVendors = () => {
    const lowerSearchText = searchText.toLowerCase();

    return vendors.filter((vendor) => {
      if (!vendor) {
        return;
      }
      const companyName = vendor?.companyName
        ? vendor?.companyName
        : null;
      const _id = vendor?._id
        ? vendor?._id
        : null;
    //   const email = job.customer?.email
    //     ? job.customer.email.toLowerCase()
    //     : null;
    //   const address = job.address
    //     ? formatAddress(job.address.readableAddress)
    //     : "";

      return (
        companyName?.includes(lowerSearchText) ||
        _id?.includes(lowerSearchText)
        // email?.includes(lowerSearchText) ||
        // address?.includes(lowerSearchText)
      );
    });
  };

  const goToVendor = (vendorId) => {
    console.log("vendorId: ", vendorId)
    navigate(`/vendor/${vendorId}`);
  };

  const getSortIcon = (column) => {
    const sortIconStyle = { color: theme.palette.greenButton.main };
    if (sortColumn === column) {
      return sortOrder === "asc" ? (
        <SortIconWrapper style={sortIconStyle}>
          <KeyboardArrowUp style={{ fontSize: "35px" }} />
        </SortIconWrapper>
      ) : (
        <SortIconWrapper style={sortIconStyle}>
          <KeyboardArrowDown style={{ fontSize: "35px" }} />
        </SortIconWrapper>
      );
    }
    return null;
  };

  const SortIconWrapper = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginLeft: "15px",
    color: "inherit",
  });

  return (
    <>
      <VendorNavbar />
      <div style={{ margin: "40px" }}>
        <div style={{ marginBottom: "16px" }}>
          <CustomTextField
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={handleSearchTextChange}
          />
        </div>

        <TableContainer component={Paper} style={{ marginBottom: "16px" }}>
          <Table>
            <TableHead>
              <TableRow sx={{ userSelect: "none", width: "100%" }}>
                <TableCell
                  onClick={() => handleSort("company")}
                  sx={{ width: "12%" }}
                >
                  <TableHeader sortable>
                    Company {getSortIcon("company")}
                  </TableHeader>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("_id")}
                  sx={{ width: "10%" }}
                >
                  <TableHeader sortable>
                    _id {getSortIcon("_id")}
                  </TableHeader>
                </TableCell>
                <TableCell
                //   onClick={() => handleSort("company")}
                  sx={{ width: "12%" }}
                >
                  <TableHeader sortable>
                    Last Logged in
                  </TableHeader>
                </TableCell>
                {/* <TableCell
                  onClick={() => handleSort("jobStatus")}
                  sx={{ width: "12%" }}
                >
                  <TableHeader sortable>
                     {getSortIcon("jobStatus")}
                  </TableHeader>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("paymentStatus")}
                  sx={{ width: "11%" }}
                >
                  <TableHeader sortable>
                    Payment {getSortIcon("paymentStatus")}
                  </TableHeader>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("address")}
                  sx={{ width: "24%" }}
                >
                  <TableHeader sortable>
                    Address {getSortIcon("address")}
                  </TableHeader>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("customerName")}
                  sx={{ width: "13%" }}
                >
                  <TableHeader sortable>
                    Customer {getSortIcon("customerName")}
                  </TableHeader>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("customerPhone")}
                  sx={{ width: "13%" }}
                >
                  <TableHeader sortable>
                    Phone {getSortIcon("phone ")}
                  </TableHeader>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <LoadingIcon />
                  </TableCell>
                </TableRow>
              ) : (
                vendors &&
                vendors?.length > 0 &&
                filteredVendors().map((vendor) => (
                  <ClickableTableRow
                    key={vendor._id}
                    onClick={() => goToVendor(vendor._id)}
                  >
                    <TableCell>
                      {vendor.companyName ? (
                        <Typography sx={{ fontSize: "18px" }}>
                          {vendor.companyName}
                        </Typography>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {vendor._id ? vendor._id : null}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {vendor?.lastLoggedIn?.timeStamp ? formatDateTime(vendor.lastLoggedIn.timeStamp) : "-"}
                      </Typography>
                    </TableCell>
                    {/* <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {job.jobStatus && formatStatus(job.jobStatus)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {job.paymentStatus &&
                          formatStatus(job.invoice?.paymentStatus)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {formatAddressNoZip(job.address.readableAddress)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {determineName(job.customer)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {formatPhoneNumber(job.customer.phoneNumber)}
                      </Typography>
                    </TableCell> */}
                  </ClickableTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default Jobs;
