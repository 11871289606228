import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingIcon from "../../components/commonComponents/LoadingIcon";
import CouponModal from "../../modals/CouponModal";
import { Coupon } from "../../utils/httpUtils";
import { useTheme } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { GreenButton } from "../reusableComponents/Buttons";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  styled,
  IconButton,
} from "@mui/material";
import { TableHeader } from "../reusableComponents/Typography";

const CouponSettings = () => {
  const theme = useTheme();
  const [coupons, setCoupons] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("expirationDate");
  const [couponsLoading, setCouponsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState("title");
  const [displayCouponModal, setDisplayCouponModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:600px)");

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  useEffect(() => {
    getCoupons();
  }, []);

  const getCoupons = async () => {
    Coupon()
      .getJson("getCoupons")
      .then((response) => {
        setCoupons(response.data.coupons);
        setCouponsLoading(false);
      });
  };

  const handleSort = (column) => {
    setSortOrder((prevOrder) =>
      column === sortColumn ? (prevOrder === "asc" ? "desc" : "asc") : "asc"
    );
    setSortColumn(column);
  };

  const handleFilterOptionChange = (event) => {
    setSearchText("");
    setFilterOption(event.target.value);
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredCoupons = () => {
    if (!Array.isArray(coupons)) {
      return [];
    }

    const lowerSearchText = searchText.toLowerCase();

    return coupons.filter((coupon) => {
      const fieldValue = coupon[filterOption];
      let fieldValueString = fieldValue ? fieldValue.toString() : "";

      const includesSearchText = fieldValueString
        .toLowerCase()
        .includes(lowerSearchText);

      return includesSearchText;
    });
  };

  const goToCoupon = (couponId) => {
    setSelectedCoupon(couponId);
    openNewCouponModal();
  };

  const createNewCouponModal = () => {
    setSelectedCoupon(null);
    openNewCouponModal();
  };

  const openNewCouponModal = () => {
    setDisplayCouponModal(true);
  };

  const handleCloseCouponModal = () => {
    setDisplayCouponModal(false);
  };

  const handleUpdateCoupons = () => {
    getCoupons();
  };

  const deleteCoupon = (couponId) => {
    const data = {
      couponId,
    };
    Coupon()
      .deleteJson("deleteCoupon", data)
      .then((response) => {
        setCoupons(response.data.coupons);
      });
  };

  const formatDuration = (duration, durationUnit) => {
    let formattedDurationUnit =
      durationUnit.charAt(0).toUpperCase() +
      durationUnit.slice(1).toLowerCase();
    if (duration === 1) {
      formattedDurationUnit = formattedDurationUnit.slice(0, -1);
    }
    return `${duration} ${formattedDurationUnit}`;
  };

  const determineEvent = (event, couponId) => {
    const isIconButtonClicked =
      event.target.tagName === "BUTTON" ||
      event.target.tagName === "svg" ||
      event.target.tagName === "path";
    if (isIconButtonClicked) {
      console.log("delete coupon");
    } else {
      goToCoupon(couponId);
    }
  };

  return (
    <div id="vendorDashboard">
      <CouponModal
        open={displayCouponModal}
        onClose={handleCloseCouponModal}
        selectedCoupon={selectedCoupon}
        setSelectedCoupon={setSelectedCoupon}
        handleUpdateCoupons={handleUpdateCoupons}
      />
      <div style={{ margin: "40px", marginTop: "60px" }}>
        {/* <div style={{ marginBottom: "16px" }}>
          <TextField
            select
            label="Filter Option"
            variant="outlined"
            value={filterOption}
            onChange={handleFilterOptionChange}
            sx={{ marginRight: "16px", width: "200px" }}
            InputProps={{ sx: { borderRadius: "8px" } }}
          >
            <MenuItem value="title">Title</MenuItem>
            <MenuItem value="description">Description</MenuItem>
            <MenuItem value="minServices">Minimum Services</MenuItem>
            <MenuItem value="discount">Discount</MenuItem>
            <MenuItem value="duration">Duration</MenuItem>
          </TextField>
          <TextField
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={handleSearchTextChange}
            InputProps={{ sx: { borderRadius: "8px" } }}
          />
        </div> */}
        <div
          style={{
            marginBottom: "16px",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <GreenButton onClick={createNewCouponModal}>
            Create New Coupon
          </GreenButton>
        </div>

        <TableContainer component={Paper} style={{ marginBottom: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "5%" }}></TableCell>
                <TableCell
                  onClick={() => handleSort("title")}
                  sx={{ width: "20%" }}
                >
                  <TableHeader sortable>Title</TableHeader>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("description")}
                  sx={{ width: "40%" }}
                >
                  <TableHeader sortable>Description</TableHeader>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("minServices")}
                  sx={{ width: "15%" }}
                >
                  <TableHeader sortable>Min. Services</TableHeader>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("discount")}
                  sx={{ width: "10%" }}
                >
                  <TableHeader sortable>Discount</TableHeader>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("duration")}
                  sx={{ width: "10%" }}
                >
                  <TableHeader sortable>Duration</TableHeader>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {couponsLoading ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <LoadingIcon />
                  </TableCell>
                </TableRow>
              ) : (
                filteredCoupons().map((coupon) => (
                  <ClickableTableRow
                    key={coupon._id}
                    onClick={(event) => determineEvent(event, coupon._id)}
                  >
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() => deleteCoupon(coupon._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {coupon.title}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {coupon.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {coupon.minServices}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {`${coupon.discountAmount} ${coupon.discountUnit}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {formatDuration(coupon.duration, coupon.durationUnit)}
                      </Typography>
                    </TableCell>
                  </ClickableTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default CouponSettings;
