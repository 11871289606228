import React from "react";
import TextField from "@mui/material/TextField";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const CustomTextField = ({
  label,
  variant,
  fullWidth,
  value,
  onChange,
  sx,
  InputProps,
  type,
  rows,
  error,
  success,
  disabled
}) => {
  const theme = useTheme();

  console.log("SX: ", sx)

  return (
    <TextField
      label={label}
      variant={variant}
      type={type}
      fullWidth={fullWidth}
      value={value}
      onChange={onChange}
      multiline={rows ? true : false}
      rows={rows}
      success={success}
      error={error}
      disabled={disabled}
      helperText={error ? error[0] : null}
      InputProps={{
        ...InputProps,
        sx: { borderRadius: "8px", ...InputProps?.sx },
      }}
      sx={{
        ...sx,
      }}
    />
  );
};

const NumberTextField = ({
  label,
  variant,
  fullWidth,
  value,
  onChange,
  sx,
  InputProps,
}) => {
  const theme = useTheme();

  return (
    <TextField
      label={label}
      variant={variant}
      fullWidth={fullWidth}
      type="number"
      value={value}
      onChange={onChange}
      InputProps={{
        ...InputProps, // Merge incoming InputProps with default InputProps
        sx: { borderRadius: "8px", ...InputProps?.sx }, // Merge styles with incoming sx prop
      }}
      sx={{
        // marginBottom: 2, // Default margin, you can customize this as needed
        ...sx, // Merge additional styles passed via sx prop
      }}
    />
  );
};

const CustomSelect = ({
  label,
  value,
  onChange,
  options,
  sx,
  InputProps,
  variant,
  fullWidth,
  onAddNewClick
}) => {
  const theme = useTheme();

  return (
    <Select
      label={label}
      value={value !== "AddNew" ? value : ""}
      variant={variant}
      onChange={onChange}
      fullWidth={fullWidth}
      sx={{
        borderRadius: "8px",
        ...sx,
      }}
      inputProps={{
        ...InputProps,
        sx: {
          borderRadius: "8px",
          ...InputProps?.sx,
        },
      }}
    >
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
      <MenuItem value="AddNew" onClick={onAddNewClick}>
        Add New
      </MenuItem>
    </Select>
  );
};

const CustomSelectWithLabel = ({
  label,
  value,
  onChange,
  options,
  sx,
  InputProps,
  fullWidth,
  onAddNewClick,
}) => {
  const theme = useTheme();

  return (
    <TextField
      select
      label={label}
      variant="outlined"
      fullWidth={fullWidth}
      value={value !== "AddNew" ? value : ""}
      onChange={onChange}
      sx={{
        borderRadius: "8px",
        ...sx, // Merge additional styles passed via sx prop
      }}
      InputProps={{
        ...InputProps,
        sx: {
          borderRadius: "8px",
          ...InputProps?.sx,
        },
      }}
    >
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
      <MenuItem value="AddNew" onClick={onAddNewClick}>
        Add New
      </MenuItem>
    </TextField>
  );
};

const CustomSelectWithLabelNoAdd = ({
  label,
  value,
  onChange,
  options,
  sx,
  InputProps,
  fullWidth,
}) => {
  const theme = useTheme();

  return (
    <TextField
      select
      label={label}
      variant="outlined"
      fullWidth={fullWidth}
      value={value !== "AddNew" ? value : ""}
      onChange={onChange}
      sx={{
        borderRadius: "8px",
        ...sx, // Merge additional styles passed via sx prop
      }}
      InputProps={{
        ...InputProps,
        sx: {
          borderRadius: "8px",
          ...InputProps?.sx,
        },
      }}
    >
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

const CustomSelectNoAdd = ({
  label,
  value,
  onChange,
  options,
  sx,
  InputProps,
  fullWidth,
  onAddNewClick,
}) => {
  const theme = useTheme();

  return (
    <TextField
      select
      label={label}
      variant="outlined"
      fullWidth={fullWidth}
      value={value !== "AddNew" ? value : ""}
      onChange={onChange}
      sx={{
        borderRadius: "8px",
        ...sx, // Merge additional styles passed via sx prop
      }}
      InputProps={{
        ...InputProps,
        sx: {
          borderRadius: "8px",
          ...InputProps?.sx,
        },
      }}
    >
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};



export {
  CustomTextField,
  NumberTextField,
  CustomSelect,
  CustomSelectWithLabel,
  CustomSelectWithLabelNoAdd,
  CustomSelectNoAdd
};
