import React, { useEffect, useState } from "react";
import VendorNavbar from "../../../components/vendorComponents/VendorNavbar";
import { useParams } from "react-router-dom";
import {
  Customer,
  Service,
  Request,
  Email,
  Vendor,
} from "../../../utils/httpUtils";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LoadingIcon from "../../../components/commonComponents/LoadingIcon";
import { useNavigate } from "react-router-dom";
import EditCustomerModal from "../../../modals/EditCustomerModal";
import Box from "@mui/material/Box";
import RecurringServiceModal from "../../../modals/RecurringServiceModal";
import CancelRecurringModal from "../../../modals/CancelRecurringModal";
import CreateRecurringServiceModal from "../../../modals/CreateRecurringServiceModal";
import CreateJobModal from "../../../modals/CreateJobModal";
import { formatDate, formatDateTime } from "../../../utils/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Grid,
  Select,
  Button,
} from "@mui/material";
import { GreenButton } from "../../../components/reusableComponents/Buttons";
import CustomCheckbox from "../../../components/reusableComponents/Customcheckbox";

function CustomerMobile() {
  const { id } = useParams();
  const [propertyId, setPropertyId] = useState("");
  const [customer, setCustomer] = useState(null);
  const [properties, setProperties] = useState([]);
  const [displayEditCustomerModal, setDisplayEditCustomerModal] =
    useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [showRecurringServiceModal, setShowRecurringServiceModal] =
    useState(false);
  const [showCancelRecurring, setShowCancelRecurring] = useState(false);
  const [showCreateRecurring, setShowCreateRecurring] = useState(false);
  const [showCreateJobModal, setShowCreateJobModal] = useState(false);
  const [requests, setRequests] = useState([]);
  const [emails, setEmails] = useState([]);
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCustomerProperties();
  }, [id]);

  useEffect(() => {
    getRecurringServices();
    getRequests();
    getEmails();
    getJobs();
  }, [id]);

  const getEmails = () => {
    const data = {
      customerId: id,
    };
    Email()
      .getJson("getEmailsForCustomer", data)
      .then((response) => {
        setEmails(response.data.emails);
      });
  };

  const getJobs = () => {
    const data = {
      customerId: id,
    };
    Vendor()
      .getJson("getAllJobsForCustomer", data)
      .then((response) => {
        if (response?.response?.status === 200) {
          setJobs(response.data.jobs);
        } else {
          console.log("error communicating with server");
        }
      });
  };

  const getRecurringServices = () => {
    const data = {
      customerId: id,
    };
    Service()
      .getJson("getCustomerRecurringServices", data)
      .then((response) => {
        setServices(response.data.recurringServices);
      });
  };

  const getRequests = () => {
    const data = {
      customerId: id,
    };
    Request()
      .getJson("getRequests", data)
      .then((response) => {
        setRequests(response.data.requests);
      });
  };

  const getCustomerProperties = () => {
    const data = {
      id,
    };
    Customer()
      .getJson("getCustomerProperties", data)
      .then((response) => {
        setCustomer(response.data.customer);
        setProperties(response.data.properties);
        setPropertyId(response.data.properties[0]?._id || "");
      });
  };

  const goToProperty = (propertyId) => {
    navigate(`/address/${propertyId}`);
  };

  const handleUpdate = () => {
    getCustomerProperties();
  };

  const formatPhoneNumber = (value) => {
    if (!value) {
      return;
    }
    const phoneNumberDigits = value.replace(/\D/g, "");
    const formattedPhoneNumber = phoneNumberDigits.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    return formattedPhoneNumber;
  };

  const formatAddress = (fullAddress) => {
    if (!fullAddress) {
      return "";
    }
    const parts = fullAddress.split(",");
    const formattedParts = parts
      .slice(0, parts.length - 2)
      .concat(parts[parts.length - 2].split(" ").slice(0, -1))
      .join(",")
      .replace(/,+/g, ",")
      .replace(/,(\S)/g, ", $1");

    return formattedParts.trim();
  };

  const goToAddress = () => {
    if (propertyId) {
      goToProperty(propertyId);
    }
  };

  const goToCustomerJobs = () => {
    navigate(`/customers/${id}/jobs`);
  };

  const closeEditCustomerModal = () => {
    setDisplayEditCustomerModal(false);
  };

  const openEditCustomerModal = () => {
    setDisplayEditCustomerModal(true);
  };

  const handleCloseRecurringModal = () => {
    setShowRecurringServiceModal(false);
  };

  const handleCloseCancelRecurringModal = () => {
    setShowCancelRecurring(false);
  };

  const handleOpenCreateRecurringModal = () => {
    setShowCreateRecurring(true);
  };

  const handleCloseCreateRecurringModal = () => {
    setShowCreateRecurring(false);
  };

  const handleCheckboxChange = (service) => {
    if (service.recurring?.isRecurring) {
      setSelectedService(service);
      setShowCancelRecurring(true);
    } else {
      setSelectedService(service);
      setShowRecurringServiceModal(true);
    }
  };

  const handleOpenCreateJobModal = () => {
    setShowCreateJobModal(true);
  };

  const handleCloseCreateJobModal = () => {
    setShowCreateJobModal(false);
  };

  const determineEmailTitle = (email) => {
    if (email.quoteEmail) {
      return "Sent Quotes";
    }
    if (email.emailTitle) {
      return email.emailTitle;
    } else {
      return "No title found";
    }
  };

  return (
    <div>
      <VendorNavbar />
      <div style={{ marginLeft: "50px", marginRight: "50px" }}>
        <RecurringServiceModal
          open={showRecurringServiceModal}
          onClose={handleCloseRecurringModal}
          service={selectedService}
          handleUpdate={getRecurringServices}
          propertyId={propertyId}
          customerId={customer?._id}
        />
        <CancelRecurringModal
          open={showCancelRecurring}
          onClose={handleCloseCancelRecurringModal}
          service={selectedService}
          handleUpdate={getRecurringServices}
        />
        <CreateRecurringServiceModal
          open={showCreateRecurring}
          onClose={handleCloseCreateRecurringModal}
          handleUpdate={getRecurringServices}
          customerId={customer?._id}
          properties={properties}
          customer={customer}
        />
        <CreateJobModal
          open={showCreateJobModal}
          onClose={handleCloseCreateJobModal}
          handleUpdate={getRecurringServices}
          customer={customer}
          properties={properties}
        />
        {customer ? (
          <div style={{ display: "flex" }}>
            {/* Left Column */}

            <div style={{ width: "75%", marginRight: "25px" }}>
              <EditCustomerModal
                open={displayEditCustomerModal}
                setPropertyId={setPropertyId}
                onClose={closeEditCustomerModal}
                handleUpdateCustomer={getCustomerProperties}
                customer={customer}
                properties={properties}
              />
              <div style={{ position: "relative" }}>
                <Typography>customermobile</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                ></Box>
                {customer.isCommercial && (
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ marginBottom: "24px", userSelect: "none" }}
                  >
                    {customer.commercialAccountName}
                  </Typography>
                )}

                <div
                  style={{
                    marginTop: "30px",
                    padding: "20px",
                    borderRadius: "10px",
                    border: "1px solid #d5dbdeb3",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    textAlign={"left"}
                    marginBottom={"30px"}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{
                          marginBottom: "28px",
                          userSelect: "none",
                          fontWeight: "700",
                        }}
                      >
                        {properties?.length > 1 ? "Properties" : "Property"}
                      </Typography>
                    </Grid>

                    <Grid item xs={8}>
                      {properties?.length > 1 ? (
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <Select
                              value={propertyId}
                              onChange={(event) => {
                                setPropertyId(event.target.value);
                              }}
                              sx={{
                                minWidth: "450px",
                                fontSize: 20,
                                borderRadius: "10px",
                              }}
                            >
                              {properties?.map((property) => (
                                <MenuItem
                                  key={property._id}
                                  value={property._id}
                                  sx={{ fontSize: 20 }}
                                >
                                  {formatAddress(
                                    property?.address?.readableAddress
                                  )}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item>
                            <GreenButton
                              onClick={goToAddress}
                              sx={{ marginLeft: "10px" }}
                              variant="contained"
                              color="primary"
                            >
                              Go to Address
                            </GreenButton>
                            <GreenButton
                              onClick={openEditCustomerModal}
                              sx={{ marginLeft: "10px" }}
                              variant="contained"
                              color="primary"
                            >
                              Add Address
                            </GreenButton>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <Typography sx={{ fontSize: 20 }}>
                              {formatAddress(
                                properties[0]?.address.readableAddress
                              )}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <GreenButton
                              onClick={goToAddress}
                              sx={{ marginLeft: "10px" }}
                              variant="contained"
                              color="primary"
                            >
                              Go to Address
                            </GreenButton>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {/* </Card> */}
                </div>

                <Card
                  sx={{
                    marginTop: "30px",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    textAlign={"left"}
                    marginBottom={"30px"}
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        sx={{ userSelect: "none", fontWeight: "700" }}
                      >
                        Recurring Services
                      </Typography>
                    </Grid>
                    <Grid item>
                      <GreenButton
                        onClick={handleOpenCreateRecurringModal}
                        variant="contained"
                        color="primary"
                      >
                        Add Recurring Service
                      </GreenButton>
                    </Grid>
                  </Grid>

                  {services && services.length > 0 && (
                    <TableContainer component={Card}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Typography sx={{ fontSize: "22px" }}>
                                Service
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: "22px" }}>
                                Address
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: "22px" }}>
                                Price
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: "22px" }}>
                                Recurring
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: "22px" }}>
                                Last Performed
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: "22px" }}>
                                Next Suggested
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {services?.map((service, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {service.quoteTitle}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {service?.readableAddress
                                    ? formatAddress(service.readableAddress)
                                    : ""}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  ${service.quotePrice.toFixed(2)}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Grid
                                  container
                                  alignItems="center"
                                  textAlign={"left"}
                                >
                                  <Grid item sx={{ marginLeft: "-10px" }}>
                                    <CustomCheckbox
                                      checked={!!service.recurring?.isRecurring}
                                      onChange={() =>
                                        handleCheckboxChange(service)
                                      }
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Typography sx={{ fontSize: "18px" }}>
                                      {service.recurring?.recurringFrequency
                                        ? `${service.recurring.recurringFrequency} ${service.recurring.recurringUnit}`
                                        : "-"}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </TableCell>

                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {service.recurring?.lastPerformed
                                    ? formatDate(
                                        service.recurring.lastPerformed
                                      )
                                    : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {service.recurring?.recommendedNext
                                    ? formatDate(
                                        service.recurring.recommendedNext
                                      )
                                    : "-"}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Card>

                <Card
                  sx={{
                    marginTop: "30px",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    textAlign={"left"}
                    marginBottom={"30px"}
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        sx={{ userSelect: "none", fontWeight: "700" }}
                      >
                        Jobs
                      </Typography>
                    </Grid>
                    <Grid item>
                      <GreenButton
                        onClick={handleOpenCreateJobModal}
                        variant="contained"
                        color="primary"
                      >
                        Create Job
                      </GreenButton>
                    </Grid>
                  </Grid>

                  <TableContainer component={Card}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography
                              sx={{ fontSize: "22px", maxWidth: "20%" }}
                            >
                              Scheduled
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ fontSize: "22px" }}>
                              Amount
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ fontSize: "22px" }}>
                              Job Status
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ fontSize: "22px" }}>
                              Payment
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ fontSize: "22px" }}>
                              Address
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {jobs &&
                          jobs.length > 0 &&
                          jobs?.map((job, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {formatDateTime(job.scheduledDate)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  $ {job.totalPrice}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {job.jobStatus}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {job.paymentStatus}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {formatAddress(job.address.readableAddress)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </div>
            </div>

            {/* Right Column */}
            <div style={{ width: "25%" }}>
              <GreenButton
                onClick={openEditCustomerModal}
                variant="contained"
                color="primary"
              >
                Edit Customer
              </GreenButton>
              <div
                style={{
                  marginTop: "20px",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                }}
              >
                <CardContent>
                  <Typography variant="h5">
                    {customer?.firstName} {customer?.lastName}
                  </Typography>
                  <Typography>{customer?.email}</Typography>
                  <Typography>
                    {formatPhoneNumber(customer?.phoneNumber)}
                  </Typography>
                  <Typography>Lead Source: {customer?.leadSource}</Typography>
                </CardContent>
              </div>

              <Card sx={{ marginTop: "20px", borderRadius: "10px" }}>
                <CardContent>
                  <Typography variant="h5">Billing</Typography>
                  <Typography>{customer?.email}</Typography>
                  <Typography>
                    {formatPhoneNumber(customer?.phoneNumber)}
                  </Typography>
                  <Typography>Lead Source: {customer?.leadSource}</Typography>
                </CardContent>
              </Card>
              <Paper
                variant="outlined"
                style={{
                  padding: "10px",
                  marginTop: "30px",
                  height: "250px",
                  textAlign: "left",
                  borderRadius: "10px",
                }}
              >
                <Typography variant="body1">{customer?.notes}</Typography>
              </Paper>
            </div>
          </div>
        ) : (
          <div style={{ marginTop: "300px" }}>
            <LoadingIcon />
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomerMobile;
