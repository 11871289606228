import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import LoadingIcon from "../../../components/commonComponents/LoadingIcon";
import VendorNavbar from "../../../components/vendorComponents/VendorNavbar";
import { Customer } from "../../../utils/httpUtils";
import { useNavigate } from "react-router-dom";
import AddNewCustomerModal from "../../../modals/AddNewCustomerModal";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { theme } from "../../../App";
import { GreenButton } from "../../../components/reusableComponents/Buttons";
import { determineName, formatPhoneNumber } from "../../../utils/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  CardContent,
  Card,
  styled,
} from "@mui/material";
import { TableHeader } from "../../../components/reusableComponents/Typography";
import { CustomTextField } from "../../../components/reusableComponents/Inputs";

const ClickableTableRow = styled(TableRow)({
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
});

const CustomersDesktop = ({ insights }) => {
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("customerName");
  const [isLoading, setIsLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [displayNewCustomerModal, setDisplayNewCustomerModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getCustomers();
  }, []);

  const getCustomers = () => {
    Customer()
      .getJson("getCustomers")
      .then((response) => {
        if (response?.response?.status === 200) {
          setCustomers(response.data.customers);
          setIsLoading(false);
        } else {
          console.log("error communicating with server");
        }
      });
  };

  const handleSort = (column) => {
    setSortOrder((prevOrder) =>
      column === sortColumn ? (prevOrder === "asc" ? "desc" : "asc") : "asc"
    );
    setSortColumn(column);
  };

  const sortedCustomers = () => {
    const filtered = filteredCustomers();
    return [...filtered].sort((a, b) => {
      let aValue;
      let bValue;

      switch (sortColumn) {
        case "customerName":
          aValue = `${a.customerFirst} ${a.customerLast}`;
          bValue = `${b.customerFirst} ${b.customerLast}`;
          break;
        case "customerEmail":
          aValue = a.email;
          bValue = b.email;
          break;
        case "customerPhone":
          aValue = a.phoneNumber;
          bValue = b.phoneNumber;
          break;
        case "customerStatus":
          aValue = a.isActive;
          bValue = b.isActive;
          break;
        default:
          aValue = "";
          bValue = "";
      }

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      }
    });
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const openNewCustomerModal = () => {
    setDisplayNewCustomerModal(true);
  };

  const closeNewCustomerModal = () => {
    setDisplayNewCustomerModal(false);
  };

  const filteredCustomers = () => {
    const lowerSearchText = searchText.toLowerCase();

    return customers.filter((customer) => {
      const fullName = determineName(customer).toLowerCase();
      const phoneNumber = customer.phoneNumber.toLowerCase();
      const email = customer.email.toLowerCase();
      const status = determineStatus(customer).toLowerCase();

      return (
        fullName.includes(lowerSearchText) ||
        phoneNumber.includes(lowerSearchText) ||
        email.includes(lowerSearchText) ||
        status.includes(lowerSearchText)
      );
    });
  };

  const determineStatus = (customer) => {
    
    if (customer.isPastDue === true) {
      return "Past Due";
    } 
    
    if (customer.hasJobs) {
      return "Active";
    }
    
    if (customer.DurationSinceRequest <= 7) {
      return "Less than 1 week since quotes sent";
    }
    
    if (parseInt(customer.DurationSinceRequest) > 7) {
      return "More than 1 week since quotes sent";
    }
    else {
      return "-"
    }
  };

  const determineToolTipText = (customer) => {
    if (customer.hasJobs) {
      return "Customer has scheduled or completed jobs";
    } else if (customer.DurationSinceRequest !== null) {
      return customer.DurationSinceRequest <= 7
        ? "Less than 7 days since quotes were sent"
        : "More than 7 days since quotes were sent";
    } else {
      return "This customer has not been sent quotes and has no booked/completed jobs";
    }
  };

  const goToCustomer = (customerId) => {
    navigate(`/customers/${customerId}`);
  };

  const determineBackground = (customer) => {
    if (customer.isPastDue) {
      return "red";
    }
    if (customer.hasJobs) {
      return "green";
    }
    if (customer.DurationSinceRequest <= 7) {
      return "orange"
    };
    if (customer.DurationSinceRequest > 7) {
      return "black"
    }
    else {
      return "transparent"
    }
  };

  const getSortIcon = (column) => {
    const sortIconStyle = { color: theme.palette.greenButton.main };
    if (sortColumn === column) {
      return sortOrder === "asc" ? (
        <SortIconWrapper style={sortIconStyle}>
          <KeyboardArrowUp style={{ fontSize: "35px" }} />
        </SortIconWrapper>
      ) : (
        <SortIconWrapper style={sortIconStyle}>
          <KeyboardArrowDown style={{ fontSize: "35px" }} />
        </SortIconWrapper>
      );
    }
    return null;
  };

  const SortIconWrapper = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginLeft: "15px",
    color: "inherit",
    fontSize: "inherit",
  });

  return (
    <>
      <VendorNavbar />
      <AddNewCustomerModal
        open={displayNewCustomerModal}
        onClose={closeNewCustomerModal}
        updateVendor={getCustomers}
      />

      <div style={{ display: "flex" }}>
        <div style={{ width: "75%" }}>
          <div
            style={{
              margin: "40px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CustomTextField
              label="Search"
              variant="outlined"
              value={searchText}
              onChange={handleSearchTextChange}
              InputProps={{ sx: { borderRadius: "8px" } }}
              style={{ marginBottom: "16px", minWidth: "200px" }}
            />
            <div
              style={{
                display: "flex",
                alignSelf: "flex-start",
              }}
            >
              <GreenButton
                onClick={openNewCustomerModal}
                style={{ alignSelf: "flex-start" }}
              >
                Create New Customer
              </GreenButton>
            </div>

            <TableContainer component={Paper} style={{ marginTop: "16px" }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ userSelect: "none", width: "100%" }}>
                    <TableCell
                      onClick={() => handleSort("customerName")}
                      sx={{ width: "25%" }}
                    >
                      <TableHeader sortable>
                        Name {getSortIcon("customerName")}
                      </TableHeader>
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort("customerEmail")}
                      sx={{ width: "25%" }}
                    >
                      <TableHeader>
                        Email {getSortIcon("customerEmail")}
                      </TableHeader>
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort("customerPhone")}
                      sx={{ width: "25%" }}
                    >
                      <TableHeader sortable>
                        Phone {getSortIcon("customerPhone")}
                      </TableHeader>
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort("customerStatus")}
                      sx={{ width: "25%" }}
                    >
                      <TableHeader sortable>
                        Status {getSortIcon("customerStatus")}
                      </TableHeader>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <LoadingIcon />
                      </TableCell>
                    </TableRow>
                  ) : (
                    customers?.length > 0 &&
                    sortedCustomers().map((customer) => (
                      <ClickableTableRow
                        key={customer._id}
                        onClick={() => goToCustomer(customer._id)}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Tooltip
                              title={determineToolTipText(customer)}
                              arrow
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: 8,
                                }}
                                onMouseEnter={(event) =>
                                  event.currentTarget.dispatchEvent(
                                    new Event("mouseenter")
                                  )
                                }
                                onMouseLeave={(event) =>
                                  event.currentTarget.dispatchEvent(
                                    new Event("mouseleave")
                                  )
                                }
                              >
                                <div
                                  style={{
                                    width: 10,
                                    height: 10,
                                    backgroundColor: determineBackground(customer),
                                    borderRadius: "50%",
                                    marginRight: 8,
                                  }}
                                ></div>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {determineName(customer)}
                                </Typography>
                              </div>
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ fontSize: "18px" }}>
                            {customer?.email}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ fontSize: "18px" }}>
                            {formatPhoneNumber(customer.phoneNumber)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography sx={{ fontSize: "18px" }}>{determineStatus(customer)}</Typography>
                        </TableCell>
                      </ClickableTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        <div
          style={{
            width: "35%",
            border: "1px solid #ccc",
            borderRadius: "10px",
            padding: "10px",
            marginRight: "40px",
          }}
        >
          <div
            sx={{
              border: "1px solid #ccc",
              borderRadius: "10px",
              padding: "10px",
              paddingTop: "0px",
            }}
          >
            <CardContent>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  marginBottom: "28px",
                  userSelect: "none",
                  fontWeight: "700",
                }}
              >
                Customer Insights
              </Typography>
            </CardContent>

            <Card
              sx={{
                borderTop: "1px solid #ccc",
                borderBottom: "none",
                marginBottom: "10px",
                borderRadius: "10px",
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    marginBottom: "28px",
                    userSelect: "none",
                    fontWeight: "700",
                  }}
                >
                  Lead Sources
                </Typography>
                {insights?.leadSources.length > 0 ? (
                  insights?.leadSources.map((source) => {
                    return (
                      <Typography
                        sx={{
                          marginBottom: "10px",
                          userSelect: "none",
                          fontWeight: "300",
                          fontSize: "20px",
                          textAlign: "left"
                        }}
                      >
                        <span style={{ color: "green", fontSize: "24px" }}>
                          {parseInt(source.percentage).toFixed(0)}%
                        </span>{" "}
                        of your customers come from{" "}
                        <span style={{ color: "green", fontSize: "24px" }}>
                          {source.leadSource}
                        </span>
                      </Typography>
                    );
                  })
                ) : (
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      marginBottom: "28px",
                      userSelect: "none",
                      fontWeight: "300",
                    }}
                  >
                    You don't currently have any lead source information
                  </Typography>
                )}
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    marginBottom: "28px",
                    userSelect: "none",
                    fontWeight: "700",
                  }}
                >
                  Recurring Customer Data
                </Typography>
                <div style={{textAlign: "left"}}>
                <Typography
                  sx={{
                    marginBottom: "10px",
                    userSelect: "none",
                    fontWeight: "300",
                    fontSize: "20px",
                  }}
                >
                  <span style={{ color: "green", fontSize: "24px" }}>
                    {parseInt(insights?.recurring)}%
                  </span>{" "}
                  of your jobs are for recurring customers
                </Typography>
                <Typography
                  sx={{
                    marginBottom: "10px",
                    userSelect: "none",
                    fontWeight: "300",
                    fontSize: "20px",
                  }}
                >
                  <span style={{ color: "green", fontSize: "24px" }}>
                    {parseInt(
                      insights?.howManyJobs?.percentageOfRecurringCustomers
                    ).toFixed(0)}
                    %
                  </span>{" "}
                  of your customers are recurring customers
                </Typography>

                <Typography
                  sx={{
                    marginBottom: "10px",
                    userSelect: "none",
                    fontWeight: "300",
                    fontSize: "20px",
                  }}
                >
                  <span style={{ color: "green", fontSize: "24px" }}>
                    {parseInt(insights?.howManyJobs?.percentage2Jobs).toFixed(
                      0
                    )}
                    %
                  </span>{" "}
                  of your customers have booked{" "}
                  <span style={{ color: "green", fontSize: "24px" }}>2</span>{" "}
                  jobs
                </Typography>
                <Typography
                  sx={{
                    marginBottom: "10px",
                    userSelect: "none",
                    fontWeight: "300",
                    fontSize: "20px",
                  }}
                >
                  <span style={{ color: "green", fontSize: "24px" }}>
                    {parseInt(insights?.howManyJobs?.percentage4Jobs).toFixed(
                      0
                    )}
                    %
                  </span>{" "}
                  of your customers have booked{" "}
                  <span style={{ color: "green", fontSize: "24px" }}>4</span>{" "}
                  jobs
                </Typography>
                <Typography
                  sx={{
                    marginBottom: "10px",
                    userSelect: "none",
                    fontWeight: "300",
                    fontSize: "20px",
                  }}
                >
                  <span style={{ color: "green", fontSize: "24px" }}>
                    {parseInt(
                      insights?.howManyJobs?.percentage5PlusJobs
                    ).toFixed(0)}
                    %
                  </span>{" "}
                  of your customers have booked{" "}
                  <span style={{ color: "green", fontSize: "24px" }}>
                    5 or more
                  </span>{" "}
                  jobs
                </Typography>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomersDesktop;
