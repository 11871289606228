import React, { useState, useEffect, useContext } from "react";
import { Vendor } from "../utils/httpUtils";
import VendorContext from "../context/VendorContext";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  GreenButton,
  RedButton,
} from "../components/reusableComponents/Buttons";
import {
  CustomTextField,
  CustomSelectWithLabel,
} from "../components/reusableComponents/Inputs";

const CreateQuoteSetModal = ({
  open,
  onClose,
  updateVendor,
  openCategoryModal,
}) => {
  const [quotes, setQuotes] = useState([
    { quoteTitle: "", quoteDescription: "", quotePrice: "", quoteCategory: "" },
  ]);
  const [quotesetTitle, setQuotesetTitle] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [quoteSetId, setQuoteSetId] = useState("");
  const [vendorState, setVendorState] = useState();
  const { vendor, setVendor } = useContext(VendorContext);
  const [quoteCategories, setQuoteCategories] = useState(null);

  useEffect(() => {
    getVendor();
  }, [vendor]);

  useEffect(() => {
    if (open && quotes.length === 0) {
      setQuotes([
        {
          quoteTitle: "",
          quoteDescription: "",
          quotePrice: "",
          quoteCategory: "",
        },
      ]);
    }
  }, [open, quotes]);

  useEffect(() => {
    if (open) {
      const { quoteSet } = open;
      if (quoteSet) {
        setEditMode(true);
        setQuoteSetId(quoteSet._id);
        setQuotesetTitle(quoteSet.quoteSetTitle);
        setQuotes(quoteSet.quoteSet);
      }
    }
  }, [open]);

  const getVendor = () => {
    Vendor()
      .getJson("getVendor")
      .then((response) => {
        if (response?.response?.status === 200) {
          setVendorState(response.data);
          setQuoteCategories(response.data.quoteCategories);
        } else {
          console.log("error communicating with server");
        }
      });
  };

  function handleAddQuote() {
    setQuotes([
      ...quotes,
      {
        quoteTitle: "",
        quoteDescription: "",
        quotePrice: "",
        quoteCategory: "",
      },
    ]);
  }

  function handleRemoveQuote() {
    if (quotes.length > 1) {
      const updatedQuotes = [...quotes];
      updatedQuotes.pop();
      setQuotes(updatedQuotes);
    }
  }

  function handleQuoteChange(index, field, value) {
    const updatedQuotes = [...quotes];
    updatedQuotes[index][field] = value;
    setQuotes(updatedQuotes);
  }

  function handleQuotesetTitle(event) {
    setQuotesetTitle(event.target.value);
  }

  function submitQuoteSet() {
    if (editMode) {
      const data = {
        id: quoteSetId,
        quotesetTitle,
        quoteSet: quotes,
      };
      Vendor()
        .putJson("updateQuoteSet", data)
        .then((response) => {
          if (response.error) {
            console.error("API Error: ", response.error);
          } else {
            onClose();
          }
        });
    } else {
      const data = {
        quotesetTitle,
        quoteSet: quotes,
      };
      Vendor()
        .postJson("createQuoteSet", data)
        .then((response) => {
          if (response.error) {
            console.error("API Error: ", response.error);
          } else {
            updateVendor();
            onClose();
          }
        });
    }
  }

  function deleteQuoteSet() {
    if (quoteSetId) {
      const data = {
        id: quoteSetId,
      };
      Vendor()
        .deleteJson("deleteQuoteSet", data)
        .then((response) => {
          if (response.error) {
            console.error("API Error: ", response.error);
          } else {
            onClose();
          }
        });
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Quote Collection</DialogTitle>
      <DialogContent>
        <CustomTextField
          sx={{ marginTop: "10px", marginBottom: "15px" }}
          label="Collection Title"
          value={quotesetTitle}
          onChange={handleQuotesetTitle}
          fullWidth
        />
        {quotes.map((quote, index) => (
          <div key={index}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // marginBottom: "10px",
              }}
            >
              <p style={{ marginRight: "10px" }}>Quote {index + 1}</p>
              <div style={{}}>
                <DeleteIcon
                  onClick={handleRemoveQuote}
                  sx={{ cursor: "pointer" }}
                />
              </div>
            </div>

            <CustomSelectWithLabel
              label="Quote Category"
              value={quote.quoteCategory}
              onChange={(e) =>
                handleQuoteChange(index, "quoteCategory", e.target.value)
              }
              options={
                quoteCategories?.length > 0
                  ? quoteCategories.map((category) => ({
                      label: category,
                      value: category,
                    }))
                  : []
              }
              fullWidth
              onAddNewClick={openCategoryModal}
              sx={{ marginBottom: "10px" }}
            />

            <CustomTextField
              label="Quote Title"
              value={quote.quoteTitle}
              onChange={(event) =>
                handleQuoteChange(index, "quoteTitle", event.target.value)
              }
              fullWidth
              sx={{
                marginBottom: "10px",
              }}
            />
            <CustomTextField
              label="Quote Description"
              value={quote.quoteDescription}
              onChange={(event) =>
                handleQuoteChange(index, "quoteDescription", event.target.value)
              }
              fullWidth
              sx={{
                marginBottom: "10px",
              }}
            />
            <CustomTextField
              label="Price"
              value={quote.quotePrice}
              onChange={(event) => {
                const numericInput = event.target.value.replace(/[^0-9.]/g, "");
                const isValidInput = /^(\d+)?(\.\d{0,2})?$/.test(numericInput);

                if (isValidInput) {
                  handleQuoteChange(index, "quotePrice", numericInput);
                }
              }}
              fullWidth
              sx={{
                marginBottom: "10px",
              }}
            />
          </div>
        ))}
        <div style={{ textAlign: "left", marginTop: "10px" }}>
          {/* <GreenButton
            onClick={handleAddQuote}
            variant="contained"
            color="primary"
            sx={{ marginRight: "10px" }}
          >
            Add Another Quote
          </GreenButton> */}
          <AddIcon
            onClick={handleAddQuote}
            sx={{ fontSize: "40px", color: "#4f8f2f", cursor: "pointer" }}
          />
          {/* //kevin addIcon */}
          {/* <RedButton
            onClick={handleRemoveQuote}
            variant="contained"
            color="primary"
          >
            Remove Quote
          </RedButton> */}
        </div>
      </DialogContent>
      <DialogActions>
        <GreenButton onClick={submitQuoteSet}>
          {editMode ? "Update Quote Set" : "Submit"}
        </GreenButton>
        {editMode && (
          <RedButton
            onClick={deleteQuoteSet}
            variant="contained"
            color="secondary"
          >
            Delete Quote Set
          </RedButton>
        )}
        <RedButton onClick={onClose}>Close</RedButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateQuoteSetModal;
