import React, { useEffect, useState } from "react";
import { Invoice as InvoiceRoute } from "../../../utils/httpUtils";
import { useParams } from "react-router-dom";
import ManualPaymentModal from "../../../modals/ManualPaymentModal";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
} from "@mui/material";
import LoadingIcon from "../../../components/commonComponents/LoadingIcon";
import {
  formatAddressNoZip,
  formatDateStrict,
  formatAddress as formatAddressUtil,
} from "../../../utils/utils";
import { TableHeader } from "../../../components/reusableComponents/Typography";
import { GreenButton } from "../../../components/reusableComponents/Buttons";

function Invoice() {
  const { invoiceId } = useParams();
  const [invoiceData, setInvoiceData] = useState(null);
  const [customer, setCustomer] = useState({});
  const [vendor, setVendor] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [displayPaymentModal, setDisplayPaymentModal] = useState(false);

  useEffect(() => {
    if (invoiceId) {
      getInvoice(invoiceId);
    }
  }, [invoiceId]);

  const getInvoice = () => {
    const data = {
      invoiceId,
    };
    InvoiceRoute()
      .getJson("getInvoice", data)
      .then((response) => {
        if (response.response.status === 200) {
          setInvoiceData(response.data.invoice);
          setCustomer(response.data.customer);
          setVendor(response.data.vendor);
          setIsLoading(false);
        }
      });
  };

  const formatAddress = (address) => {
    const preFormatted = formatAddressNoZip(address);
    if (!address) return "";
    const addressArr = preFormatted.split(",");

    return (
      <span>
        {addressArr[0]} <br />
        {addressArr[1]}, {addressArr[2]}
      </span>
    );
  };

  const calculateSubtotal = () => {
    if (!invoiceData || !invoiceData.services) return 0;
    return invoiceData.services.reduce(
      (total, service) => total + service.servicePrice,
      0
    );
  };

  const sumPayments = () => {
    if (!invoiceData || !invoiceData.payments) {
      console.error("Invalid invoice data or payments array");
      return 0.0;
    }

    const totalAmount = invoiceData.payments.reduce((total, payment) => {
      return total + parseFloat(payment.paymentAmount);
    }, 0);

    const roundedTotal = totalAmount.toFixed(2);

    return roundedTotal;
  };

  const totalDue = () => {
    const totalPrice = invoiceData.totalPrice;
    const totalPayments = sumPayments();
    const remainingBalance = totalPrice - totalPayments;
    return remainingBalance.toFixed(2);
  };
  return (
    <div id="invoice" style={{ margin: "50px" }}>
      <ManualPaymentModal
        open={displayPaymentModal}
        onClose={() => setDisplayPaymentModal(false)}
        invoice={invoiceData}
        handleUpdate={getInvoice}
      />
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <Grid container spacing={2}>
          {/* Vendor Information */}
          <Grid item xs={12}>
            <div style={{ textAlign: "left" }}>
              <Typography variant="h6">
                <img
                  src={vendor?.logo}
                  alt="Vendor Logo"
                  style={{ height: "50px" }}
                />
                <br />
                {formatAddress(
                  vendor?.physicalLocation?.address.readableAddress
                )}
                <br />
                {vendor?.phoneNumber} | {vendor?.email}
              </Typography>
            </div>
          </Grid>

          {/* Recipient Information */}
          <Grid item xs={5}>
            <div style={{ textAlign: "left" }}>
              <Typography variant="h6">
                <span style={{ fontSize: "25px" }}>Recipient:</span>
                <br />
                <span style={{ fontSize: "30px" }}>
                  {customer?.firstName} {customer?.lastName}
                </span>
                <br />
                {formatAddress(invoiceData?.address?.readableAddress)}
              </Typography>
            </div>
          </Grid>

          {/* Invoice Information */}

          <Grid item xs={6}>
            <Grid container spacing={2} sx={{ justifyContent: "right" }}>
              {/* Left Column: Titles */}
              <Grid item xs={3} sx={{ textAlign: "left" }}>
                <Typography variant="h6">Invoice #:</Typography>
                <Typography variant="h6">Issued:</Typography>
                <Typography variant="h6">Due:</Typography>
                <Typography variant="h6">Total Due:</Typography>
              </Grid>
              {/* Right Column: Values */}
              <Grid item xs={3} sx={{ textAlign: "right" }}>
                <Typography variant="h6">
                  {invoiceData?.invoiceNumber}
                </Typography>
                <Typography variant="h6">
                  {formatDateStrict(invoiceData?.dateCompleted)}
                </Typography>
                <Typography variant="h6">
                  {formatDateStrict(invoiceData?.paymentDue)}
                </Typography>
                <Typography variant="h6">${totalDue()}</Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Services Rendered */}
          <Grid item xs={12}>
            <div style={{ textAlign: "left" }}>
              <Typography variant="h6">For Services Rendered:</Typography>
            </div>
          </Grid>

          {/* Service Details */}
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableHeader>Service</TableHeader>
                    </TableCell>
                    <TableCell>
                      <TableHeader>Description</TableHeader>
                    </TableCell>
                    <TableCell>
                      <TableHeader>Price</TableHeader>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceData?.services?.map((service, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography sx={{ fontSize: "16px" }}>
                          {service.serviceTitle}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: "16px" }}>
                          {service.serviceDescription}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: "16px" }}>
                          ${service.servicePrice.toFixed(2)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "right",
              marginRight: "100px",
              marginTop: "50px",
            }}
          >
            {/* Left Column: Titles */}
            <Grid item xs={1} sx={{ textAlign: "left" }}>
              <Typography variant="h6">Subtotal:</Typography>

              {invoiceData.tax ? (
                <Typography variant="h6">Tax:</Typography>
              ) : null}

              <Typography variant="h6">Total:</Typography>

              <Typography variant="h6">Payments:</Typography>

              <Typography variant="h6">Total Due:</Typography>
            </Grid>
            {/* Right Column: Values */}
            <Grid item xs={2} sx={{ textAlign: "right" }}>
              <Typography variant="h6">
                ${calculateSubtotal().toFixed(2)}
              </Typography>

              {invoiceData.tax ? (
                <Typography variant="h6">
                  ${invoiceData.tax.toFixed(2)}
                </Typography>
              ) : null}

              <Typography variant="h6">
                ${invoiceData.totalPrice.toFixed(2)}
              </Typography>

              {invoiceData.payments && invoiceData.payments.length > 0 ? (
                <Typography variant="h6">-${sumPayments()}</Typography>
              ) : (
                <Typography variant="h6">$0</Typography>
              )}

              {/* {invoiceData.payments && invoiceData.payments.length > 0 ? ( */}
              <Typography variant="h6">${totalDue()}</Typography>

              <GreenButton
                onClick={() => setDisplayPaymentModal(true)}
                sx={{ marginTop: "20px" }}
                disabled={totalDue() === 0}
              >
                Add Payment
              </GreenButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default Invoice;
