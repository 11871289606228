import React, { useEffect, useState } from "react";
import EmailModal from "../../modals/EmailModal";
import { Email } from "../../utils/httpUtils";
import LoadingIcon from "../../components/commonComponents/LoadingIcon";
import { GreenButton } from "../reusableComponents/Buttons";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  IconButton,
  Typography,
} from "@mui/material";
import { TableHeader } from "../reusableComponents/Typography";

function EmailSettings() {
  const [automatedEmails, setAutomatedEmails] = useState([]);
  const [displayEmailModal, setDisplayEmailModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [sentEmails, setSentEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  useEffect(() => {
    getEmails();
    getSentEmails();
  }, []);

  const getEmails = () => {
    Email()
      .getJson("getAutomatedEmails")
      .then((response) => {
        setAutomatedEmails(response.data.automatedEmails);
        setIsLoading(false);
      });
  };

  const getSentEmails = () => {
    Email()
      .getJson("getAllEmailsForVendor")
      .then((response) => {
        setSentEmails(response.data.emails);
      });
  };

  const handleCloseEmailModal = () => {
    setDisplayEmailModal(false);
  };

  const openNewEmailModal = () => {
    setSelectedEmail(null);
    setDisplayEmailModal(true);
  };

  const determineTriggerName = (triggerCode) => {
    switch (triggerCode) {
      case 1:
        return "Quotes Sent";
      case 2:
        return "Job Booked";
      case 3:
        return "Job Completed";
      case 4:
        return "Scheduled Job";
      case 5:
        return "Recurring Job";
      default:
        return null;
    }
  };

  const configureDuration = (email) => {
    let durationUnit =
      email.durationUnit.charAt(0).toUpperCase() +
      email.durationUnit.slice(1).toLowerCase();
    let beforeAfter =
      email.beforeAfter.charAt(0).toUpperCase() +
      email.beforeAfter.slice(1).toLowerCase();
    if (email.duration === 1) {
      durationUnit = durationUnit.slice(0, -1);
    }

    return `${email.duration} ${durationUnit} ${beforeAfter}`;
  };

  const deleteEmail = (emailId) => {
    const data = {
      emailId,
    };
    Email()
      .deleteJson("deleteAutomatedEmail", data)
      .then((response) => {
        getEmails();
      });
  };

  const determineEvent = (event, email) => {
    const isIconButtonClicked =
      event.target.tagName === "BUTTON" ||
      event.target.tagName === "svg" ||
      event.target.tagName === "path";
    if (isIconButtonClicked) {
      deleteEmail(email._id);
    } else {
      setSelectedEmail(email);
      setDisplayEmailModal(true);
    }
  };

  return (
    <div>
      <EmailModal
        open={displayEmailModal}
        onClose={handleCloseEmailModal}
        selectedEmail={selectedEmail}
        handleUpdateEmails={getEmails}
      />
      <div id="vendorDashboard">
        <Typography>This feature is coming soon!</Typography>
        <div
          style={{
            marginBottom: "16px",
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "50px",
          }}
        >
          <GreenButton onClick={openNewEmailModal} disabled>
            New Automated Email
          </GreenButton>
        </div>

        <TableContainer component={Paper} style={{ marginBottom: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* kevin */}
                <TableCell sx={{ width: "5%" }}></TableCell>
                <TableCell sx={{ width: "30%" }}>
                  <TableHeader sortable>Title</TableHeader>
                </TableCell>
                <TableCell sx={{ width: "20%" }}>
                  <TableHeader sortable>When to Send</TableHeader>
                </TableCell>
                <TableCell sx={{ width: "10%" }}>
                  <TableHeader sortable>Trigger</TableHeader>
                </TableCell>
                <TableCell sx={{ width: "10%" }}>
                  <TableHeader sortable>Total Sent</TableHeader>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <LoadingIcon />
                  </TableCell>
                </TableRow>
              ) : (
                automatedEmails.length > 0 &&
                automatedEmails.map((email) => (
                  <ClickableTableRow
                    key={email._id}
                    onClick={(event) => determineEvent(event, email)}
                  >
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() => deleteEmail(email._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {email.emailTitle}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {configureDuration(email)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {determineTriggerName(email.triggerCode)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {email.totalEmailsSent}
                      </Typography>
                    </TableCell>
                  </ClickableTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default EmailSettings;
