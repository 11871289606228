import React, { useEffect, useState } from "react";
import { Container, Typography, Button, Checkbox } from "@mui/material";
import QuoteCard from "../../components/clientComponents/QuoteCard";
import JobCreatedModal from "../../modals/JobCreatedModal";
import { formatDate } from "../../utils/utils";
import { GreenButton } from "../../components/reusableComponents/Buttons";
import CustomCheckbox from "../../components/reusableComponents/Customcheckbox";

export default function QuotesLarge({
  selectedQuotes,
  onSelectQuote,
  requestServices,
  error,
  success,
  job,
  vendor,
  property,
  allQuotes,
  coupons,
  appliedCoupons,
  dollarDiscount,
}) {
  const [displayModal, setDisplayModal] = useState(false);
  const [requestedQuotes, setRequestedQuotes] = useState([]);
  const [unrequestedQuotes, setUnrequestedQuotes] = useState([]);



  useEffect(() => {
    let requested = [];
    let unrequested = [];
    if (allQuotes) {
      allQuotes.forEach((quote) => {
        if (quote.requested) {
          requested.push(quote);
        } else if (!quote.requested) {
          unrequested.push(quote);
        }
      });
      setRequestedQuotes(requested);
      setUnrequestedQuotes(unrequested);
    }
  }, [allQuotes]);

  useEffect(() => {
    if (success) {
      openModal();
    }
  }, [job]);

  useEffect(() => {}, [appliedCoupons, coupons]);

  const closeModal = () => {
    setDisplayModal(false);
  };

  const openModal = () => {
    setDisplayModal(true);
  };

  // Function to check if a coupon is applied
  const isCouponApplied = (coupon) => {
    return appliedCoupons.some(
      (appliedCoupon) => appliedCoupon._id === coupon._id
    );
  };

  return (
    <div className={"quotesLarge"}>
      {/* {console.log("allquotes: ", allQuotes)} */}
      <Container
        sx={{
          bgcolor: "white",
          minHeight: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          marginTop: "10px",
        }}
      >
        <Container>
          {console.log("allQuotes in quotesLarge.jsx: ", allQuotes)}
          {/* Vendor information */}
          {vendor && (
            <Container
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "flex",
                  xl: "flex",
                },
                flexDirection: "column",
                fontWeight: 300,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                fontSize: 22,
                padding: 3,
                justifyContent: "center",
              }}
            >
              <div className={"vendorInfoContainer"}>
                <div className={"vendorInfoLogo"}>
                  <img
                    src={vendor.logo}
                    className={"vendorLogo"}
                    alt="Vendor Logo"
                  />
                </div>
                <div className={"vendorInfo"}>
                  <div>
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "center",
                        fontWeight: 300,
                        letterSpacing: ".3rem",
                        color: "inherit",
                        textDecoration: "none",
                        fontSize: 18,
                      }}
                    >
                      {vendor.companyName}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "center",
                        fontWeight: 300,
                        letterSpacing: ".3rem",
                        color: "inherit",
                        textDecoration: "none",
                        fontSize: 18,
                      }}
                    >
                      {vendor.website}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "center",
                        fontWeight: 300,
                        letterSpacing: ".3rem",
                        color: "inherit",
                        textDecoration: "none",
                        fontSize: 18,
                      }}
                    >
                      {vendor.email}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "center",
                        fontWeight: 300,
                        letterSpacing: ".3rem",
                        color: "inherit",
                        textDecoration: "none",
                        fontSize: 18,
                      }}
                    >
                      {vendor?.phoneNumber}
                    </Typography>
                  </div>
                </div>
              </div>
            </Container>
          )}

          {/* Error and success messages */}
          {error && <div className={"createJobError"}>Error: {error}</div>}
          {success && <div className={"jobCreationSuccess"}>{success}</div>}
          {displayModal && (
            <JobCreatedModal
              onClose={closeModal}
              open={displayModal}
              vendor={vendor}
              job={job}
            />
          )}

          {/* Quotes for the property */}
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              userSelect: "none",
              fontWeight: 300,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
              fontSize: 26,
              marginBottom: "20px",
            }}
          >
            Quotes for {property?.address.number} {property?.address.street}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              userSelect: "none",
              fontWeight: 300,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
              fontSize: 24,
              marginBottom: "20px",
            }}
          >
            Select the services you'd like to add to your job
          </Typography>

          {/* Requested quotes */}
          {requestedQuotes?.length > 0 && (
            <div>
              <Typography
                sx={{
                  textAlign: "center",
                  userSelect: "none",
                  fontWeight: 300,
                  letterSpacing: ".1rem",
                  color: "inherit",
                  textDecoration: "none",
                  fontSize: 24,
                  marginBottom: "20px",
                }}
              >
                {requestedQuotes?.length === 1
                  ? "Here is the quote you requested"
                  : "Here are the quotes you requested:"}
              </Typography>
              {requestedQuotes.map((quote) => (
                <div key={quote._id} className={"quoteCardContainer"}>
                  <QuoteCard
                    quote={quote}
                    isSelected={selectedQuotes.includes(quote._id)}
                    onSelect={() => onSelectQuote(quote._id)}
                    selectedQuotes={selectedQuotes}
                  />
                </div>
              ))}
            </div>
          )}

          {/* Unrequested quotes */}
          {unrequestedQuotes?.length > 0 &&
            (requestedQuotes?.length > 0 ? (
              <div>
                <Typography
                  sx={{
                    textAlign: "center",
                    userSelect: "none",
                    fontWeight: 300,
                    letterSpacing: ".1rem",
                    color: "inherit",
                    textDecoration: "none",
                    fontSize: 24,
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  Explore additional quotes for your property below
                </Typography>
                {unrequestedQuotes.map((quote) => (
                  <div key={quote._id} className={"quoteCardContainer"}>
                    <QuoteCard
                      quote={quote}
                      isSelected={selectedQuotes.includes(quote._id)}
                      onSelect={() => onSelectQuote(quote._id)}
                      selectedQuotes={selectedQuotes}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {unrequestedQuotes.map((quote) => (
                  <div key={quote._id} className={"quoteCardContainer"}>
                    <QuoteCard
                      quote={quote}
                      isSelected={selectedQuotes.includes(quote._id)}
                      onSelect={() => onSelectQuote(quote._id)}
                      selectedQuotes={selectedQuotes}
                    />
                  </div>
                ))}
              </div>
            ))}

          {/* Coupons */}
          {coupons && coupons.length > 0 && (
            <div>
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  userSelect: "none",
                  fontWeight: 300,
                  letterSpacing: ".1rem",
                  color: "inherit",
                  textDecoration: "none",
                  fontSize: 24,
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                Coupons
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  userSelect: "none",
                  fontWeight: 300,
                  letterSpacing: ".1rem",
                  color: "inherit",
                  textDecoration: "none",
                  fontSize: 16,
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                (Coupons are automatically applied)
              </Typography>

              {coupons.map((coupon) => (
                <div
                  key={coupon._id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CustomCheckbox checked={isCouponApplied(coupon)} disabled />
                  <div>
                    <Typography>
                      {coupon.description}.{" "}
                      {coupon.expiryDate
                        ? `Offer valid until ${formatDate(coupon.expiryDate)}.`
                        : null}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div
            className={"requestServicesButton"}
            style={{ marginTop: "30px" }}
          >
            <GreenButton
              variant="contained"
              color="primary"
              onClick={requestServices}
              disabled={selectedQuotes?.length === 0}
            >
              Book services
            </GreenButton>
          </div>
        </Container>
      </Container>
    </div>
  );
}
